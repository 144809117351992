import api from ".";
import * as config from "./../../extra/config";
import { getParamUrlByKey } from "../../extra/utils";

const payment_url = config.payment_url;

// code là mã giỏ hàng
export function getTransaction({ code, utm_source, product_code }) {
  const utm_source_params = utm_source ? `utm_source=${utm_source}` : "";
  const product_code_params = product_code
    ? utm_source_params
      ? `&product_code=${product_code}`
      : `product_code=${product_code}`
    : "";
  return api.apiForm(
    "GET",
    `/api/transaction/${code}?${utm_source_params}${product_code_params}`
  );
}

export function payment(paidBy, code, password) {
  return api.apiForm(
    "GET",
    `/api/pay/${paidBy}/${code}?password=${password}&token=${getParamUrlByKey(
      "token"
    )}&callback=${encodeURIComponent(`${payment_url}?code=${code}`)}`
  );
}

export function statusTransaction(code) {
  return api.apiForm("GET", `/api/transaction/${code}/status`);
}

export function getCompleteInfo(code) {
  return api.apiForm("GET", `/api/transaction/${code}/complete`);
}

export function getDetail9Pay(code) {
  return api.apiForm("GET", `/api/pay/9pay/${code}`);
}

export function getDetailGPay(code) {
  return api.apiForm("GET", `/api/pay/gpay/${code}`);
}
