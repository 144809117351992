import { useEffect } from "react";
import { message } from "antd";
import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "../assets/icon/loading.gif";
import { getParamUrlByKey } from "../../../extra/utils";
import { getTransaction } from "../../../controller/api/api_payment";

const Waiting = ({ loading, title }) => {
  const classes = styles();
  const code = getParamUrlByKey("code");
  const utm_source = getParamUrlByKey("utm_source") || null;
  const product_code = getParamUrlByKey("product_code") || null;
  const waiting = getParamUrlByKey("waiting");

  useEffect(() => {
    if (waiting) {
      getDetailTran();
    }
  }, []);

  const getDetailTran = async () => {
    const res = await getTransaction({ code, utm_source, product_code });
    if (res instanceof Error) {
      return message.error("Có lỗi xảy ra", 2);
    }
    const detailTran = res.data?.data;
    localStorage.setItem(
      "dataTran",
      JSON.stringify({
        fees: detailTran?.total - detailTran?.rewardAmount,
        method: "partner",
        code: getParamUrlByKey("code"),
        free: false,
      })
    );
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={!!loading}>
        <div style={{ textAlign: "center" }}>
          <div className="label">
            <b>Giao dịch đang được xử lý</b>
          </div>
          <img src={Loading} style={{ width: 60 }} />
          <div className="label">Vui lòng chờ</div>
        </div>
      </Backdrop>
    </>
  );
};

const styles = makeStyles(() => ({
  backdrop: {
    zIndex: 100,
    background: "#ffffff !important",
    color: "#fff",
    flexDirection: "column",
    "& .label": {
      lineHeight: 1.5,
      fontSize: 14,
      color: "#646D69",
    },
    maxWidth: 800,
    margin: "0px auto",
  },
}));

export default Waiting;
