import { useEffect } from "react";
import { Box, Button, Divider, Grid } from "@mui/material";
import { useStyles } from "../styles";
import {
  formatDate,
  formatMoney,
  getParamUrlByKey,
} from "../../../extra/utils";
import { ArrowRightOutlined, CheckCircleFilled } from "@ant-design/icons";
import ChevronRigth from "./../assets/icon/chevron-rigth.svg";
import BottomButton from "../components/BottomButton";
import PaymentMethods from "./../components/PaymentMethods";
import _ from "lodash";
import Waiting from "../components/Waiting";
import ConfirmPassword from "../components/confirmPassword";

export default function Step1({ state, dispatch, isApp, smScreen }) {
  const { dataStep1, openWaiting } = state;
  const classes = useStyles();

  useEffect(() => {
    // console.log(dataStep1);
    if (!dataStep1?.free) {
      dispatch("openChooseMethots");
    }
  }, []);

  const nextPayment = () => {
    dispatch("setLocalStorageDataTran");
    // Những đơn thanh toán đổi thưởng sẽ cần nhập mật khẩu tiếp tục thanh toán
    if (dataStep1?.transaction?.confirmPassword) {
      dispatch("openConfirmPass");
      return;
    }
    dispatch("payment");
  };

  return (
    <>
      <Waiting loading={openWaiting} />
      <ConfirmPassword {...{ dispatch, isApp, state }} />
      {!isApp ? (
        <div className={classes.body}>
          <div className={classes.viewWeb}>
            <div className={classes.containerWeb}>
              <Grid container spacing={1}>
                <Grid item xs={7} md={7} className={classes.order}>
                  <div className="header">Sản phẩm đã chọn</div>
                  <OrderItems
                    listOrder={dataStep1?.order}
                    classes={classes}
                    isApp={isApp}
                  />
                </Grid>
                <Grid item xs={5} md={5} className={classes.method}>
                  <div className="header">Chọn phương thức thanh toán</div>
                  <PaymentMethod {...{ dataStep1, classes, dispatch, isApp }} />
                  <Divider className="divider" />
                  <PayInfo data={dataStep1?.transaction} classes={classes} />
                  <div className={classes.payBtn}>
                    <Button variant="contained" onClick={() => nextPayment()}>
                      {!smScreen ? (
                        <>
                          <div className={classes.btnRow}>
                            <div className="total">
                              {formatMoney(
                                +dataStep1?.transaction?.total)}{" "}
                              <div className="vnd">VNĐ</div>
                            </div>
                          </div>
                          <div className="text">
                            Thanh toán <ArrowRightOutlined />
                          </div>
                        </>
                      ) : (
                        <>
                          <div></div>
                          <div className="label">
                            Thanh toán <ArrowRightOutlined />
                          </div>
                          <div></div>
                        </>
                      )}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <>
          <PaymentMethods {...{ dispatch, isApp, state }} />
          <div className={classes.wrapper}>
            <Header title="Sản phẩm đã chọn" />
            <div className={classes.list}>
              <OrderItems
                listOrder={dataStep1?.order}
                classes={classes}
                isApp={isApp}
              />
            </div>
          </div>
          <div
            style={{
              background: "#F3F3F3",
              height: "10px",
            }}
          ></div>
          <div className={classes.wrapper}>
            <Header title="Tổng cộng" />
            <PayInfo
              isApp={isApp}
              data={dataStep1?.transaction}
              classes={classes}
            />
          </div>
          <div
            style={{
              background: "#F3F3F3",
              height: "10px",
              marginTop: "-10px",
              // marginBottom: "-10px",
            }}
          ></div>
          <div
            style={{ display: dataStep1?.free ? "none" : "" }}
            className={classes.wrapper}
          >
            <div
              onClick={() => dispatch("openChooseMethots")}
              style={{
                fontSize: 18,
                marginTop: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="header"
            >
              Phương thức thanh toán
              <img src={ChevronRigth} style={{ height: 18 }} />
            </div>
            {(dataStep1.cardTypes || []).map((data, i) => (
              <div
                key={i}
                onClick={() => dispatch("openChooseMethots")}
                style={{
                  display: dataStep1?.sl === data?.id ? "flex" : "none",
                  alignItems: "center",
                  color: "#3F76DF",
                  marginTop: 20,
                }}
              >
                <img
                  src={data?.iconActive}
                  style={{ marginRight: 10, maxHeight: 30 }}
                />
                <span style={{ fontSize: 15 }}>{data?.name}</span>
              </div>
            ))}
            <PaymentMethod {...{ dataStep1, classes, dispatch, isApp }} />
          </div>
          <div
            style={{
              height:
                dataStep1?.order.length < 2
                  ? dataStep1?.order.length === 0
                    ? "600px"
                    : "400px"
                  : "200px",
            }}
          ></div>
          <BottomButton
            onClick={() => nextPayment()}
            onBack={() => dispatch("onBack")}
            title="Thanh toán"
          />
        </>
      )}
    </>
  );
}

const Header = ({ title, dispatch }) => (
  <>
    <div
      style={{
        fontSize: 18,
        marginTop: "0px",
      }}
      className="header"
    >
      {title}
    </div>
  </>
);

const Dialog = ({ title }) => (
  <>
    <div style={{ fontSize: 18, marginTop: "0px" }} className="header">
      {title}
    </div>
  </>
);

const OrderItems = ({ listOrder, classes, isApp }) => (
  <div className={isApp ? classes.listOrderApp : classes.listOrder}>
    {(listOrder || []).map((i, index) => (
      <div key={index}>
        {i?.product_id === 31 ? ( //tomato-combo
          <>
            {i?.insured_info?.packages.map((item, idx) => (
              <ItemCombo
                key={idx}
                item={item}
                classes={classes}
                isApp={isApp}
              />
            ))}
          </>
        ) : (
          <div className={isApp ? classes.boxItems : classes.boxItem}>
            <div className="row w-100">
              <img
                alt="icon"
                src={i?.icon}
                className="icon"
                style={{ width: isApp ? "20%" : "10%", objectFit: "contain" }}
              />
              <div className="info">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="bold name"
                >
                  <div>{i?.product_title || ""}</div>
                  <div style={{ display: isApp ? "" : "none" }}>
                    {formatMoney(i.fees)} <span className="price">VNĐ</span>
                  </div>
                </div>
                <div style={{ alignItems: "flex-start" }} className="itemRow">
                  <span className="gray" style={{ minWidth: 91 }}>
                    Người mua BH:
                  </span>
                  <span
                    style={{ textAlign: "right" }}
                    className="capitalize black"
                  >
                    {i?.buyer_name || ""}
                  </span>
                </div>
                <div
                  style={{ display: isApp ? "none" : "" }}
                  className="itemRow"
                >
                  <span className="gray">Phí:</span>
                  <span>
                    {formatMoney(i.fees)} <span className="price">VNĐ</span>
                  </span>
                </div>
                {!!i?.insured_info?.insuranceFees?.feesDetail
                  ?.carOccupantFees && (
                  <>
                    <div className="itemRow">
                      <span className="gray">BH vật chất xe:</span>
                      <span>
                        {formatMoney(
                          i?.insured_info?.insuranceFees?.feesDetail?.baseFees
                        ) || 0}{" "}
                        <span className="price">VNĐ</span>
                      </span>
                    </div>
                    <div className="itemRow">
                      <span className="gray">BHTN người ngồi trên xe:</span>
                      <span>
                        {formatMoney(
                          i?.insured_info?.insuranceFees?.feesDetail
                            ?.carOccupantFees
                        ) || 0}{" "}
                        <span className="price">VNĐ</span>
                      </span>
                    </div>
                  </>
                )}
                {(i?.buyer_private_id ||
                  i?.insured_info?.insuredPrivateId ||
                  i?.insured_info?.listOfInsuredPeople?.[0]
                    ?.insuredPrivateId) && (
                  <div className="itemRow">
                    <span className="gray">CMND/ CCCD/ Hộ chiếu:</span>
                    <span>
                      {i.buyer_private_id ||
                        i?.insured_info?.insuredPrivateId ||
                        i?.insured_info?.listOfInsuredPeople[0]
                          .insuredPrivateId}
                    </span>
                  </div>
                )}
                {/* mat cap xm */}
                {i?.insured_info?.licenseOrChassisOrEngine && (
                  <div className="itemRow">
                    <span className="gray">Biển số:</span>
                    <span className="license">
                      {i?.insured_info?.licenseOrChassisOrEngine}
                    </span>
                  </div>
                )}
                {i?.insured_info?.license && (
                  <div className="itemRow">
                    <span className="gray">Biển số:</span>
                    <span className="license">{i?.insured_info?.license}</span>
                  </div>
                )}
                {i?.insured_info?.chassis && (
                  <>
                    <div className="itemRow">
                      <span className="gray">Số khung:</span>
                      <span className="license">
                        {i?.insured_info?.chassis}
                      </span>
                    </div>
                  </>
                )}
                {i?.insured_info?.engine && (
                  <>
                    <div className="itemRow">
                      <span className="gray">Số máy:</span>
                      <span className="license">{i?.insured_info?.engine}</span>
                    </div>
                  </>
                )}
                <div className="itemRow">
                  <span style={{ padding: 0 }} className="gray">
                    Ngày hiệu lực:{" "}
                  </span>
                  <span>
                    {formatDate(i?.begin_date) || "N/A"} -{" "}
                    {formatDate(i?.end_date) || "N/A"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ))}
  </div>
);

const PaymentMethod = ({ isApp, dataStep1, classes, dispatch }) => (
  <div
    style={{ display: isApp || dataStep1?.free ? "none" : "" }}
    className={isApp ? classes.boxCardApp : classes.boxCard}
  >
    <Grid
      container
      direction="row"
      alignItems="start"
      justifyContent={"space-around"}
      spacing={1}
    >
      {(dataStep1.cardTypes || []).map((i, idx) => (
        <Grid item key={idx} xs={4} md={4} lg={isApp ? 4 : 3}>
          <Box
            className={`card ${dataStep1.sl === i?.id ? "border" : ""}`}
            onClick={() => dispatch({ type: "selectCard", id: i?.id })}
            style={{ background: "#FFF" }}
          >
            <CheckCircleFilled
              className={`checkIcon ${dataStep1.sl === i?.id ? "" : "none"}`}
              hidden={dataStep1.sl !== idx}
              style={{ color: "green" }}
            />
            <div className="img">
              <img alt={idx} src={i?.icon} style={{ width: "100%" }}></img>
            </div>
          </Box>
          <div style={{textAlign: 'center', marginTop: 7}}>{i?.name || ""}</div>
        </Grid>
      ))}
    </Grid>
  </div>
);

const PayInfo = ({ data, classes, isApp }) => (
  <>
    <div style={{ margin: "10px 0px" }} className={classes.row}>
      <div style={{ fontSize: 14, color: isApp ? "#646D69" : "" }}>
        Thành tiền
      </div>
      <div className="price">
        {typeof data.feesTrial === "undefined"
          ? formatMoney(data?.provisional) || 0
          : 0}
        <div
          style={{ color: isApp ? "#646D69" : "", fontWeight: 400 }}
          className="vnd"
        >
          VNĐ
        </div>
      </div>
    </div>
    <div style={{ margin: "10px 0px" }} className={classes.row}>
      <div style={{ fontSize: 14, color: isApp ? "#646D69" : "" }}>
        Giảm giá
      </div>
      <div className="price">
        {+data?.discount > 0 ? "-" : ""}
        {typeof data.feesTrial === "undefined"
          ? formatMoney(data?.discount) || "0"
          : "0"}
        <div
          style={{ color: isApp ? "#646D69" : "", fontWeight: 400 }}
          className="vnd"
        >
          VNĐ
        </div>
      </div>
    </div>
    <div
      style={{
        margin: "10px 0px",
        display: +data?.rewardAmount === 0 ? "none" : "",
      }}
      className={classes.row}
    >
      <div style={{ fontSize: 14, color: isApp ? "#646D69" : "" }}>
        Áp dụng tiền thưởng
      </div>
      <div className="price">
        {/* rewardAmount là tiền thưởng */}-
        {formatMoney(data?.rewardAmount) || "0"}{" "}
        <div
          style={{ color: isApp ? "#646D69" : "", fontWeight: 400 }}
          className="vnd"
        >
          VNĐ
        </div>
      </div>
    </div>
    <Divider className="divider" />
    <div style={{ margin: "10px 0px" }} className={classes.row}>
      <div style={{ fontSize: 14, color: isApp ? "#646D69" : "" }}>
        Số tiền thanh toán
      </div>
      <div className="price">
        {formatMoney(+data?.total) || 0}{" "}
        <div
          style={{ color: isApp ? "#646D69" : "", fontWeight: 400 }}
          className="vnd"
        >
          VNĐ
        </div>
      </div>
    </div>
  </>
);

const ItemCombo = ({ key, item, classes, isApp }) => {
  return (
    <>
      <div
        key={key}
        style={{ minHeight: "100px" }}
        className={isApp ? classes.boxItems : classes.boxItem}
      >
        <div className="row">
          <Box style={{ background: `${item?.color}` }} className="boxImage">
            <div className="code">{item?.code}</div>
            <div>{item?.product}</div>
          </Box>
          <div style={{ width: "100%", marginLeft: 5 }}>
            <div className="uppercase">{item?.name || ""}</div>
            <div className="itemRow">
              <span className="gray">Giá gốc:</span>
              <span className="bold black">
                {formatMoney(item?.price?.corner) || 0}{" "}
                <span className="vnd">VNĐ</span>
              </span>
            </div>
            <div className="itemRow">
              <span className="gray">Giá áp dụng:</span>
              <span className="bold red">
                {formatMoney(item?.price?.discount) || 0}{" "}
                <span className="vnd">VNĐ</span>
              </span>
            </div>
            <div className="itemRow">
              <span className="gray">Tiết kiệm:</span>{" "}
              <span className="bold black">
                {formatMoney(item?.price?.saving) || 0}{" "}
                <span className="vnd">VNĐ</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
